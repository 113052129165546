@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes mui {
  0% {
    width: 50%;
    opacity: 0.1;
    background: currentcolor;
  }

  50% {
    width: 200%;
    opacity: 0.2;
    background: radial-gradient(
      circle,
      transparent 0%,
      transparent 75%,
      currentcolor 75%
    );
  }

  100% {
    width: 200%;
    opacity: 0;
    background: radial-gradient(
      circle,
      transparent 0%,
      transparent 75%,
      currentcolor 75%
    );
  }
}
