// base colors
$orange: #fe3b1f;
$white: #fff;
$black: #1d252c;
$night: $black;
$gray-2: #202733;
$neutralDark: #3b4147;
$neutralDark-light: #474c52;
$gray: #6f747b;
$neutralGray: #a2a9ad;
$neutral: #dce1e4;
$neutralDarkGray: #f8f9fc;
$lightNeutralGray: #f5f6f9;

// app colors
$on3night: $night;
$on3primary: $orange;
$nilGray: #6e7c84;
$blue: #556cd6;
$green: #19857b;
$focusBackground: #f5f6f9;
$focusBorder: #e4e8f2;
$opacityBackground: #18191c;
$lightGray: #eef1f9;
$premiumGreen: #70db6a;
$premiumGreenDark: #70ed69;
$container: $white;
$ksrBlue: #2071e3;
$ksrDarkBLue: #123084;
$positiveDay: #00c853;
$positiveDayDark: #0ea414;
$positiveNight: #00c853;
$positiveNightNew: #41f293;
$newGreen: #29f085;
$negativeDay: #e53935;
$negativeNight: #ff7673;
$interestBeige: #f4efdc;
$interestRed: #eb5757;
$interestRedNew: #ee6e6e;
$interestOrange: #f97f3b;
$reminderOrange: #fa8e54;
$interestYellow: #f8e297;
$softballAmerica: #d7b744;
$modalBackground: #fafafa;
$darkGold: #deb22b;
$gold: #f2c94c;
$lightNeutral: #e9ecf3;
$opacityBorder: #ced4da;
$opacityGrayBackground: #eeeff2;
$neutralBackground: #e5e5e5;
$borderBox: $black;
$royalBlue: rbg(65, 105, 225);
$iOSprimary: #2e7cf6;
$osPrimary: #0d88ff;
$twitterBlue: #55acee;
$ice: #5ff1f2;
$subHeadingBorder: #e9ecf4;
$elitePrimary: #0d8bff;

// z-indexes
$zIndex-header: 1000;
$zIndex-popover: $zIndex-header + 1;
$zIndex-ads: 1005;
$zIndex-sticky-header: 1;
$zIndex-confidence-bar: 1;
$zIndex-sticky-header-prediction-center: $zIndex-confidence-bar + 1;
$zIndex-menu: 101;

// break points
$mobile-ad-height: 50px;
$nav-width: 320px;
$mobile-width: 375px;
$small-width: 450px;
$tablet-width: 600px;
$mid-width: 800px;
$fivestar-mid-width: 860px;
$sidebar-break: 960px;
$desktop-width: 1200px;
$full-width: 1400px;

// sticky header positions
$on3-sticky-height-desktop: 114px;
$on3-sticky-height-mobile: 99px;

// icon sizes
$icon-large: 50px;
$icon-medium: 30px;
$icon-small: 20px;

// fonts
$geist: 'Geist', arial, helvetica, sans-serif;
$archivo-narrow: 'archivo narrow', arial, sans-serif;
$archivo: 'archivo', arial, sans-serif;
$merriweather: merriweather, georgia, times, serif;
$helvetica-now:
  'Helvetica Now Display',
  #{$geist};
$roboto: 'Roboto', sans-serif;

// Industry Comparison Grid Layouts

//  Player Item
$ic-column-style-player-mobile: 30px 70px 1fr 0.5fr;
$ic-column-style-player-mobile-noJerseyOrRank: 70px 1fr 0.5fr;
$ic-column-style-player-desktop: 40px 60px 1fr;
$ic-column-style-player-desktop-noJerseyOrRank: 90px 1fr;
$ic-column-style-player-full: 40px 90px 1fr;
$ic-column-style-player-full-noJerseyOrRank: 90px 1fr;
$ic-column-style-draft-player-mobile: 80px 70px 1fr 0.5fr;
$ic-column-style-draft-player-1200: 80px 110px 1.75fr;
$ic-column-style-draft-player-1400: 105px 110px 1.5fr;

//  List and Header
$ic-column-style-desktop: 40px 70px 1.5fr 1fr 1fr 1fr 1fr 0.75fr;
$ic-column-style-desktop-noStatus: 40px 70px 1.5fr 1fr 1fr 1fr 1fr;
$ic-column-style-desktop-noJerseyOrRank: 100px 1.5fr 1fr 1fr 1fr 1fr 1fr;
$ic-column-style-full: 40px 110px 1.75fr 1fr 1fr 1fr 1fr 0.75fr;
$ic-column-style-full-noStatus: 40px 110px 1.75fr 1fr 1fr 1fr 1fr;
$ic-column-style-full-noJerseyOrRank: 110px 1.5fr 1fr 1fr 1fr 1fr 1fr;
$ic-column-style-draft-1200: 80px 110px 1.75fr 1fr 1fr 1fr 1fr;
$ic-column-style-draft-1400: 105px 110px 1.5fr 1fr 1fr 1fr 1fr;

//  Transfer Portal IC List & Header
$ic-column-style-tp-desktop: 40px 70px 1.5fr 1fr 1fr 1fr 1.25fr;
$ic-column-style-tp-full: 40px 100px 1.5fr 1fr 1fr 1fr 1.25fr;
$ic-column-style-tp-desktop-noJerseyOrRank: 100px 1.25fr 1fr 1fr 1fr 1.25fr;
$ic-column-style-tp-full-noJerseyOrRank: 110px 1.75fr 1fr 1fr 1fr 0.75fr;

// Border Radius
$radius-xl: 16px;
$radius-lg: 12px;
$radius-md: 6px;
$radius-sm: 3px;
$radius-xs: 1px;
$radius-round: 50%;
$radius-full: 9999px;

// Spacing
$spacing-xl: 6rem;
$spacing-lg: 3rem;
$spacing-regular: 1.5rem;
$spacing-sm: 0.75rem;
$spacing-slim: 0.5rem;
$spacing-compact: 0.375rem;
$spacing-xs: 0.25rem;
